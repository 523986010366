import React from "react";
import Navbar from "../Navbar.tsx";
import Footer from "../Footer.tsx";
import ProductList from "./ProductList.tsx";
import {
  plantGrow1,
  plantGrow2,
  product100,
  product101,
  product102,
  product103,
  product104,
  product105,
  product106,
  product107,
  product108,
  product109,
  product110,
  product111,
  product112,
  product113,
  product114,
  product115,
  product116,
  product117,
  product118,
  product119,
  product120,
  product121,
  product122,
  product123,
  product124,
  product125,
  product126,
  product127,
  product128,
  product129,
  product130,
  product131,
  product132,
  product133,
  product134,
  product135,
  product136,
  product137,
  product138,
  product139,
  product140,
  product141,
  product142,
  product143,
  product144,
  product145,
  product146,
  product147,
  product148,
  product149,
  product150,
  product151,
  product152,
  product153,
  product154,
  product155,
  product156,
  product157,
  product158,
  product159,
  product160,
  product161,
  product162,
  product163,
  product164,
  product165,
  product166,
  product167,
  product168,
  product169,
  product170,
  product171,
  Product18,
  Product19,
  Product2,
  product20,
  product21,
  product22,
  product23,
  product24,
  product25,
  product26,
  product27,
  product28,
  product29,
  product30,
  product31,
  product32,
  product33,
  product34,
  product35,
  product36,
  product37,
  product38,
  product39,
  product40,
  product41,
  product42,
  product43,
  product44,
  product45,
  product46,
  product47,
  product48,
  product49,
  Product5,
  product50,
  product51,
  product52,
  product53,
  product54,
  product55,
  product56,
  product57,
  product58,
  product59,
  product60,
  product61,
  product62,
  product63,
  product64,
  product65,
  product66,
  product67,
  product68,
  product69,
  product70,
  product71,
  product72,
  product73,
  product74,
  product75,
  product76,
  product77,
  product78,
  product79,
  product80,
  product81,
  product82,
  product83,
  product84,
  product85,
  product86,
  product87,
  product88,
  product89,
  product90,
  product91,
  product92,
  product93,
  product94,
  product95,
  product96,
  product97,
  product98,
  product99,
  productBanner,
} from "../../Assets.tsx";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import WhatsAppButton from "../../Common-Components/WhatsAppButton.tsx";
import AboutUsBannerCarousel from "../../Common-Components/AboutUsBannerCarousel.tsx";
import { useTranslation } from "react-i18next";

gsap.registerPlugin(ScrollTrigger);

const ProductPage = () => {
  const webstyle = {
    sideBarOpition1: {
      border: "1px solid black",
      padding: "15px 0 8px 0",
      fontSize: "18px",
      margin: "0",
      color: "black",
      //   borderTopRightRadius: "40px",
      // borderBottomLeftRadius: "40px"
    },
    sideBarOpitions: {
      border: "1px solid black",
      padding: "10px",
      fontSize: "18px",
      margin: "0 0 0 0",
      color: "black",
      //   borderTopRightRadius: "40px",
      // borderBottomLeftRadius: "40px"
    },
    Heading: {
      // background: "#c0e61d",
      color: "black",
      marginTop: "10px",
      textTransform: "capitalize",
      fontSize: "35px",
    },
  };

  useGSAP(() => {
    gsap.from(".gsapProductBanner", {
      opacity: 0,
      duration: 1,
      scale: 0.3,
      y: 100,
      delay: 1,
    });
  });

  const { t } = useTranslation();

  const products2 = [
    {
      id: 4,
      name: "Arrow-AQ Ampelomyces Quisqualis Bio Fungicides",
      price: 950,
      imageUrl: product75,
      chemicalType: "other",
      physicalState: "Powder",
      releaseType: "Controlled",
      packagingSize: "1 litre",
      description:"For more details about this product, click below on shop now",
      additionalImages: [product75, product76, product77],
      productUrl:"https://kisanshopee.com/product/arrow-aq-2/"
    },
    {
      id: 26,
      name: "BIO Treat F",
      price: 475,
      imageUrl: product64,
      chemicalType: "other",
      physicalState: "Powder",
      releaseType: "Controlled",
      packagingSize: "200 gram",
      description:"For more details about this product, click below on shop now",
      additionalImages: [product64, product65, product66],
      productUrl:"https://kisanshopee.com/product/biotreat-f/"
    },
    {
      id: 26,
      name: "BIO Treat B",
      price: 595,
      imageUrl: product67,
      chemicalType: "other",
      physicalState: "Powder",
      releaseType: "Controlled",
      packagingSize: "200 gram",
      description:"For more details about this product, click below on shop now",
      additionalImages: [product67, product68, product69],
    productUrl:"https://kisanshopee.com/product/biotreat-b-pseudomonas-fluorescens/"
    },
    {
      id: 27,
      name: "BIO Trust",
      price: 345,
      imageUrl: product70,
      chemicalType: "other",
      physicalState: "Powder",
      releaseType: "Controlled",
      packagingSize: "500 gram",
      description:"For more details about this product, click below on shop now",
      additionalImages: [product70, product71],
      productUrl:"https://kisanshopee.com/product/bio-trust/"
    },
    {
      id: 28,
      name: "Midex 777",
      price: 950,
      imageUrl: product72,
      chemicalType: "other",
      physicalState: "Powder",
      releaseType: "Controlled",
      packagingSize: "500 gram",
      description:"For more details about this product, click below on shop now",
      additionalImages: [product72, product73, product74],
      productUrl:"https://kisanshopee.com/product/mildex-777/"
    },
  ];
  const products3 = [
    {
      id: 5,
      name: "Bio Bullet Containing Metarhizium Anisopliae",
      price: 595,
      description:"For more details about this product, click below on shop now",
      imageUrl: product84,
      Classification: "Insecticide",
      application: "Agriculture",
      physicalState: "Liquid",
      packagingSize: "1 litre",
      additionalImages: [product84, product85, product86],
      productUrl:"https://kisanshopee.com/product/bio-bullet/"
    },
    {
      id: 19,
      name: "Nematokill Paecilomyces lilacinus For Control of Nematodes Infection",
      price: 810,
      description:"For more details about this product, click below on shop now",
      imageUrl: product78,
      application: "Agriculture",
      physicalState: "Powder",
      packagingSize: "1 litre",
      additionalImages: [product78, product79, product80],
      productUrl:"https://kisanshopee.com/product/nemat-kill/"
    },
    {
      id: 29,
      name: "Bio Bassiana",
      price: 595,
      description:"For more details about this product, click below on shop now",
      imageUrl: product81,
      application: "Agriculture",
      physicalState: "Powder",
      packagingSize: "1 litre",
      additionalImages: [product81, product82, product83],
      productUrl:"https://kisanshopee.com/product/bio-bassiana/"
    },
    {
      id: 30,
      name: "Verticon",
      price: 595,
      description:"For more details about this product, click below on shop now",
      imageUrl: product87,
      application: "Agriculture",
      physicalState: "Powder",
      packagingSize: "1 litre",
      additionalImages: [product87, product88, product89],
      productUrl:"https://kisanshopee.com/product/verticon/"
    },
    {
      id: 31,
      name: "Biotox",
      price: 925,
      description:"For more details about this product, click below on shop now",
      imageUrl: product90,
      application: "Agriculture",
      physicalState: "liquid",
      packagingSize: "1 litre",
      additionalImages: [product90, product91, product92],
      productUrl:"https://kisanshopee.com/product/biotox/"
    },
  ];
  const products4 = [
    {
      id: 7,
      name: "All Source 9 Bio Kit",
      price: 1550,
      description:"For more details about this product, click below on shop now",
      imageUrl: Product5,
      application: "Agriculture",
      physicalState: "Powder",
      releaseType: "Controlled",
      storage: "Dry Place",
      additionalImages: [Product5, product93, product94],
      productUrl:"https://kisanshopee.com/product/all-source-bio-kit/"

    },
    {
      id: 32,
      name: "Cane Star",
      price: 3770,
      description:"For more details about this product, click below on shop now",
      imageUrl: product96,
      application: "Agriculture",
      physicalState: "Powder",
      releaseType: "Controlled",
      storage: "Dry Place",
      additionalImages: [product96, product97, product98],
      productUrl:"https://kisanshopee.com/product/bio-kit/"

    },
  ];
  const products5 = [
    {
      id: 9,
      name: "Effective Micro Organism Gold Plus Fertilizers",
      price: 900,
      description:"For more details about this product, click below on shop now",
      imageUrl: product38,
      application: "Agriculture",
      physicalState: "Liquid",
      storage: "Dry Place",
      Classfication: " Compound Fertilizer",
      additionalImages: [, product38, product39, product40],
      productUrl:"https://kisanshopee.com/product/e-m-glod-plus/"
    },
    {
      id: 18,
      name: "Germinate 21 Biological Seed Treatment",
      price: 55,
      description:"For more details about this product, click below on shop now",
      imageUrl: product33,
      application: "Agriculture",
      physicalState: "Powder",
      releaseType: "Controlled",
      additionalImages: [, product33, product50],
      productUrl:"https://kisanshopee.com/product/germinate-21/"

    },
    {
      id: 22,
      name: "Goldmine Mycorrhizal concentrate with growth promoters",
      price: 975,
      description:"For more details about this product, click below on shop now",
      imageUrl: product43,
      application: "Agriculture",
      physicalState: "Powder",
      releaseType: "Controlled",
      additionalImages: [, product43, product44, product45],
      productUrl:"https://kisanshopee.com/product/goldmine/"
    },
    {
      id: 10,
      name: "K Source Potassium Mobilizer Bacteria",
      description:"For more details about this product, click below on shop now",
      price: 515,
      imageUrl: product25,
      application: " Agriculture",
      Classfication: " Compound Fertilizer",
      physicalState: "Liquid",
      releaseType: "Controlled",
      additionalImages: [, product25, product41, product42],
      productUrl:"https://kisanshopee.com/product/k-source/"
    },
    {
      id: 23,
      name: "N Source Atmospheric Nitrogen fixing Bacteria",
      description:"For more details about this product, click below on shop now",
      price: 595,
      imageUrl: product48,
      application: " Agriculture",
      Classfication: " Compound Fertilizer",
      physicalState: "Liquid",
      releaseType: "Controlled",
      additionalImages: [, product48, product49, product51],
      productUrl:"https://kisanshopee.com/product/n-source/"
    },
    {
      id: 24,
      name: "BioMixing",
      description:"For more details about this product, click below on shop now",
      price: 895,
      imageUrl: product52,
      application: " Agriculture",
      Classfication: " Compound Fertilizer",
      physicalState: "Liquid",
      releaseType: "Controlled",
      additionalImages: [, product52, product53, product54],
      productUrl:"https://kisanshopee.com/product/bio-mixin/"
    },
    {
      id: 25,
      name: "M source",
      description:"For more details about this product, click below on shop now",
      price: 595,
      imageUrl: product55,
      application: " Agriculture",
      Classfication: " Compound Fertilizer",
      physicalState: "Liquid",
      releaseType: "Controlled",
      additionalImages: [, product55, product56, product57],
      productUrl:"https://kisanshopee.com/product/american-cheese-singles/"
    },
    {
      id: 26,
      name: "D source",
      description:"For more details about this product, click below on shop now",
      price: 450,
      imageUrl: product58,
      application: " Agriculture",
      Classfication: " Compound Fertilizer",
      physicalState: "Liquid",
      releaseType: "Controlled",
      additionalImages: [, product58, product59, product60],
      productUrl:"https://kisanshopee.com/product/d-source-decomposting-bacteria/"
    },
    {
      id: 27,
      name: "N source",
      description:"For more details about this product, click below on shop now",
      price: 595,
      imageUrl: product62,
      application: " Agriculture",
      Classfication: " Compound Fertilizer",
      physicalState: "Liquid",
      releaseType: "Controlled",
      additionalImages: [, product62, product63, product61],
      productUrl:"https://kisanshopee.com/product/n-source/"
    },
    {
      id: 13,
      name: "P Source Phosphorous Solubilising Bacteria Fertilizers",
      description:"For more details about this product, click below on shop now",
      price: 515,
      imageUrl: product28,
      application: " Agriculture",
      Classification: " Compound Fertilizer",
      physicalState: "Liquid",
      storage: "Dry Place",
      additionalImages: [product28, product46, product47],
      productUrl:"https://kisanshopee.com/product/p-source/"
    },
    {
      id: 11,
      name: "Bio Combi",
      description:"For more details about this product, click below on shop now",
      price: 1250,
      imageUrl: product116,
      additionalImages: [product116, product117],
      productUrl:"https://kisanshopee.com/product/bio-combi/"
    },
  ];

  const products7 = [
    {
      id: 12,
      name: "Miracle G 9 Foliar Anti-Transpirant And Protective Plant Coating",
      description:"For more details about this product, click below on shop now",
      price: 850,
      imageUrl: product27,
      application: " Agriculture ",
      Classfication: " Compound Fertilizer",
      chemicalType: "Other",
      physicalState: "Liquid",
      releaseType: "Controlled",
      storage: "Dry Place",
      additionalImages: [product27, product107, product108],
      productUrl:"https://kisanshopee.com/product/miracle-g-9/"
    },
    {
      id: 15,
      name: "Uneem 10000 Plus to Control Lepidopterous Pests Insecticides",
      price: 1750,
      description:"For more details about this product, click below on shop now",
      imageUrl: product30,
      application: "Agriculture",
      physicalState: "Liquid",
      releaseType: "Controlled",
      Classification: "Insecticide",
      additionalImages: [product30, product99, product100],
      productUrl:"https://kisanshopee.com/product/uneem-10000-plus/"
    },
    {
      id: 33,
      name: "Leaf Herbal Insecticide",
      price: 1510,
      description:"For more details about this product, click below on shop now",
      imageUrl: product101,
      application: "Agriculture",
      physicalState: "Liquid",
      releaseType: "Controlled",
      Classification: "Insecticide",
      additionalImages: [product101, product102, product103],
      productUrl:"https://kisanshopee.com/product/b-leaf/"
    },
    {
      id: 34,
      name: "Deter Botanic natural alkaloids",
      price: 2235,
      description:"For more details about this product, click below on shop now",
      imageUrl: product104,
      application: "Agriculture",
      physicalState: "Liquid",
      releaseType: "Controlled",
      Classification: "Insecticide",
      additionalImages: [product104, product105, product106],
      productUrl:"https://kisanshopee.com/product/100-butter-french-croissants/"
    },
  ];
  const products9 = [
    {
      id: 17,
      name: "Bactricell Immuno Modulator Better Control",
      price: 870,
      description:"For more details about this product, click below on shop now",
      imageUrl: product118,
      additionalImages: [product118, product119],
      productUrl:"https://kisanshopee.com/product/bactricell-2/"
    },
    {
      id: 35,
      name: " unisulfa",
      price: 415,
      description:"For more details about this product, click below on shop now",
      imageUrl: product120,
      additionalImages: [product120, product121, product122],
      productUrl:"https://kisanshopee.com/product/uni-sulf-22-lime-sulfur/"
    },
    {
      id: 36,
      name: " unisulfa",
      price: 190,
      description:"For more details about this product, click below on shop now",
      imageUrl: product123,
      additionalImages: [product123, product124, product125],
      productUrl:"https://kisanshopee.com/product/fresh-brown-coconut/"
    },
    {
      id: 3,
      name: "Delcon Multifunctional Fungicide",
      price: 910,
      description:"For more details about this product, click below on shop now",
      imageUrl: product20,
      chemicalType: "Other",
      physicalState: "Liquid",
      releaseType: "Controlled",
      additionalImages: [product20, product126, product127],
      productUrl:"https://kisanshopee.com/product/delcon/"
    },
    {
      id: 38,
      name: "Bordo M",
      price: 1000,
      description:"For more details about this product, click below on shop now",
      imageUrl: product128,
      chemicalType: "Other",
      physicalState: "Liquid",
      releaseType: "Controlled",
      additionalImages: [product128, product129],
      productUrl:"https://kisanshopee.com/product/bordo-m/"
    },
  ];

  const products12 = [
    {
      id: 20,
      name: "Supremo Amino Acid Based Chelated Micro-Nutrient Mixture",
      price: 545,
      description:"For more details about this product, click below on shop now",
      imageUrl: product137,
      application: "Agriculture",
      physicalState: "Powder",
      additionalImages: [product137, product138],
      productUrl:"https://kisanshopee.com/product/supremo-chelated-micro-nutrient-mixture/"
    },
    {
      id: 40,
      name: "UNI-BOR",
      price: 360,
      description:"For more details about this product, click below on shop now",
      imageUrl: product135,
      application: "Agriculture",
      physicalState: "Powder",
      additionalImages: [product135, product136],
      productUrl:"https://kisanshopee.com/product/uni-bor-boron-20/"
    },
    {
      id: 41,
      name: "UNI-Ferrous",
      price: 830,
      description:"For more details about this product, click below on shop now",
      imageUrl: product139,
      application: "Agriculture",
      physicalState: "Powder",
      additionalImages: [product139, product140],
      productUrl:"https://kisanshopee.com/product/uni-ferrous-chelated-ferrous-12/"
    },
    {
      id: 42,
      name: "Microsoul",
      price: 1050,
      description:"For more details about this product, click below on shop now",
      imageUrl: product141,
      application: "Agriculture",
      physicalState: "Powder",
      additionalImages: [product141, product142,product143],
      productUrl:"https://kisanshopee.com/product/microsoul-soil-application/"
    },
    {
      id: 43,
      name: "Uni Mag",
      price: 830,
      description:"For more details about this product, click below on shop now",
      imageUrl: product144,
      application: "Agriculture",
      physicalState: "Powder",
      additionalImages: [product144, product145],
      productUrl:"https://kisanshopee.com/product/uni-mag-chelated-magnesium-6/"
    },
    {
      id: 43,
      name: "Uni Zinc",
      price: 830,
      description:"For more details about this product, click below on shop now",
      imageUrl: product146,
      application: "Agriculture",
      physicalState: "Powder",
      additionalImages: [product146, product147],
      productUrl:"https://kisanshopee.com/product/uni-zinc-chelated-zinc-12-2/"
    },
    {
      id: 44,
      name: "Uni Manganese",
      price: 830,
      description:"For more details about this product, click below on shop now",
      imageUrl: product148,
      application: "Agriculture",
      physicalState: "Powder",
      additionalImages: [product148, product149],
      productUrl:"https://kisanshopee.com/product/uni-mag-chelated-magnesium-6/"
    },
    {
      id: 45,
      name: "Uni Cal",
      price: 1000,
      description:"For more details about this product, click below on shop now",

      imageUrl: product150,
      application: "Agriculture",
      physicalState: "Powder",
      additionalImages: [product150, product151],
      productUrl:"https://kisanshopee.com/product/uni-cal-chelated-calcium-10/"
    },
    {
      id: 45,
      name: "Uni Ferrous Eddha",
      price: 1000,
      description:"For more details about this product, click below on shop now",

      imageUrl: product152,
      application: "Agriculture",
      physicalState: "Powder",
      additionalImages: [product152, product153],
    },
  ];
  const products13 = [
    {
      id: 21,
      name: "Greencell Algaecide",
      price: 570,
      description:"For more details about this product, click below on shop now",
      imageUrl: product36,
      application: "Agriculture",
      physicalState: "Liquid",
      storage: "Dry Place",
      additionalImages: [product36, product130, product131],
      productUrl:"https://kisanshopee.com/product/greencell/"
    },
  ];
  const products15 = [
    {
      id: 14,
      name: "Trikaal",
      price: 3360,
      description:"For more details about this product, click below on shop now",

      imageUrl: product109,
      application: "Agriculture",
      physicalState: "Liquid",
      storage: "Dry Place",
      additionalImages: [product109, product110, product111],
      productUrl:"https://kisanshopee.com/product/trikaal-2/"
    },
    {
      id: 35,
      name: "Helix",
      price: 950,
      imageUrl: product114,
      application: "Agriculture",
      physicalState: "Liquid",
      storage: "Dry Place",
      description:"For more details about this product, click below on shop now",

      additionalImages: [product114, product115],
      productUrl:"https://kisanshopee.com/product/helix-universals-snail-killer/"
    },
    {
      id: 6,
      name: "Shool botanical  Extract Insecticide For Bio Organic Plant Enhancer",
      price: 1790,
      imageUrl: product23,
      Classification: "Insecticide",
      physicalState: "Powder",
      releaseType: "Controlled",
      packagingSize: "500 ml",
      additionalImages: [product23, product112, product113],
      description:"For more details about this product, click below on shop now",

      productUrl:"https://kisanshopee.com/product/shool-2/"
    },
  ];
  const products16 = [
    {
      id: 40,
      name: "Activate Sp",
      price: 460,
      description:"For more details about this product, click below on shop now",
      imageUrl: product132,
      application: "Agriculture",
      physicalState: "Liquid",
      storage: "Dry Place",
      additionalImages: [product132, product133, product134],
      productUrl:"https://kisanshopee.com/product/activate-sp/"
    },
  ];
  const products17 = [
    {
      id: 50,
      name: "Florin",
      price: 1720,
      description:"For more details about this product, click below on shop now",

      imageUrl: product154,
      application: "Agriculture",
      physicalState: "Liquid",
      storage: "Dry Place",
      additionalImages: [product154, product155,product156],
      productUrl:"https://kisanshopee.com/product/florin/"
    },
    {
      id: 51,
      name: "Bio Soulmix",
      price: 460,
      description:"For more details about this product, click below on shop now",

      imageUrl: product157,
      application: "Agriculture",
      physicalState: "Liquid",
      storage: "Dry Place",
      additionalImages: [product157, product158,product162],
      productUrl:"https://kisanshopee.com/product/bio-soulmix/"
    },
    {
      id: 45,
      name: "Humicon",
      price: 475,
      description:"For more details about this product, click below on shop now",

      imageUrl: product159,
      application: "Agriculture",
      physicalState: "Liquid",
      storage: "Dry Place",
      additionalImages: [product159, product160,product161],
      productUrl:"https://kisanshopee.com/product/humicon-humic-acid-90-wsp/"
    },
    {
      id: 46,
      name: "Protean Plus",
      price: 1380,
      description:"For more details about this product, click below on shop now",

      imageUrl: product163,
      application: "Agriculture",
      physicalState: "Liquid",
      storage: "Dry Place",
      additionalImages: [product163, product164,product165],
      productUrl:"https://kisanshopee.com/product/protein-plus-extreme/"
    },
    {
      id: 47,
      name: "New Classic",
      price: 2530,
      description:"For more details about this product, click below on shop now",

      imageUrl: product166,
      application: "Agriculture",
      physicalState: "Liquid",
      storage: "Dry Place",
      additionalImages: [product166, product167,product168],
      productUrl:"https://kisanshopee.com/product/new-classic-for-cell-elongation-cell-development/"
    },
    {
      id: 47,
      name: "Prolexin",
      price: 570,
      description:"For more details about this product, click below on shop now",
     
      imageUrl: product169,
      application: "Agriculture",
      physicalState: "Liquid",
      storage: "Dry Place",
      additionalImages: [product169, product170,product171],
      productUrl:"https://kisanshopee.com/product/prolexin/"
    },
  ];
  return (
    <div>
      <Navbar />
      <AboutUsBannerCarousel />
      <div
        style={{ width: "100%", height: "auto", display: "flex" }}
        className="backgroundImageOurProductPage"
      >
        <div
          style={{
            width: "20%",
            // borderRight: "1px solid black",
            // margin: "0 20px 0 0px",
          }}
          className="ProductSideBar"
        >

          <a
            href="#Bio-Fungicides"
            style={{ color: "black", textDecoration: "none", margin: "0" }}
            className="sideBarOption"
          >
            <p
              style={{
                ...webstyle.sideBarOpitions,
                textAlign: "center",
                textTransform: "capitalize",
              }}
              className="sideBarOption"
            >
              {t("navbar.BioFungicides")}
            </p>
          </a>
          <a
            href="#Bio-insecticides"
            style={{ color: "black", textDecoration: "none" }}
            className="sideBarOption"
          >
            <p
              style={{
                ...webstyle.sideBarOpitions,
                textAlign: "center",
                textTransform: "capitalize",
              }}
              className="sideBarOption "
            >
              {t("navbar.BioInsecticides")}
            </p>
          </a>
          <a
            href="#Bio-kit"
            style={{ color: "black", textDecoration: "none" }}
            className="sideBarOption"
          >
            <p
              style={{
                ...webstyle.sideBarOpitions,
                textAlign: "center",
                textTransform: "capitalize",
              }}
              className="sideBarOption"
            >
              {t("navbar.BioKit")}
            </p>
          </a>
          <a
            href="#Bio-Fertilizers"
            style={{ color: "black", textDecoration: "none" }}
            className="sideBarOption"
          >
            <p
              className="sideBarOption"
              style={{
                ...webstyle.sideBarOpitions,
                textAlign: "center",
                textTransform: "capitalize",
              }}
            >
              {t("navbar.BioFertilizers")}
            </p>
          </a>
          <a
            href="#Botanic-Extract-Insecticides"
            style={{ color: "black", textDecoration: "none" }}
            className="sideBarOption"
          >
            <p
              className="sideBarOption"
              style={{
                ...webstyle.sideBarOpitions,
                textAlign: "center",
                textTransform: "capitalize",
              }}
            >
              {t("navbar.BEI")}{" "}
            </p>
          </a>
        
          <a
            href="#Chemical-Fungicides"
            style={{ color: "black", textDecoration: "none" }}
            className="sideBarOption"
          >
            <p
              className="sideBarOption"
              style={{
                ...webstyle.sideBarOpitions,
                textAlign: "center",
                textTransform: "capitalize",
              }}
            >
              {t("navbar.ChemicalFungicides")}
            </p>
          </a>

          <a
            href="#Micro-Nutrients"
            style={{ color: "black", textDecoration: "none" }}
            className="sideBarOption"
          >
            <p
              className="sideBarOption"
              style={{
                ...webstyle.sideBarOpitions,
                textAlign: "center",
                textTransform: "capitalize",
              }}
            >
              {t("navbar.microNutrients")}
            </p>
          </a>
          <a
            href="#algacide"
            style={{ color: "black", textDecoration: "none" }}
            className="sideBarOption"
          >
            <p
              className="sideBarOption"
              style={{
                ...webstyle.sideBarOpitions,
                textAlign: "center",
                textTransform: "capitalize",
              }}
            >
              {t("navbar.algacide")}
            </p>
          </a>
          <a
            href="#Chemical-Insecticides"
            style={{ color: "black", textDecoration: "none" }}
            className="sideBarOption"
          >
            <p
              className="sideBarOption"
              style={{
                ...webstyle.sideBarOpitions,
                textAlign: "center",
                textTransform: "capitalize",
              }}
            >
              {t("navbar.ChemicalInsecticides")}
            </p>
          </a>
          <a
            href="#Sticker-Spreader"
            style={{ color: "black", textDecoration: "none" }}
            className="sideBarOption"
          >
            <p
              className="sideBarOption"
              style={{
                ...webstyle.sideBarOpitions,
                textAlign: "center",
                textTransform: "capitalize",
              }}
            >
               {t("navbar.Stsp")}
            </p>
          </a>
          <a
            href="#PGR"
            style={{ color: "black", textDecoration: "none" }}
            className="sideBarOption"
          >
            <p
              className="sideBarOption"
              style={{
                ...webstyle.sideBarOpitions,
                textAlign: "center",
                textTransform: "capitalize",
              }}
            >
               {t("navbar.pgr")}
            </p>
          </a>
        </div>
        <div
          style={{ width: "80%", height: "auto" }}
          className="ProductCardsContainer"
        >
          <div id="Bio-Fungicides" className="backgroundImageOurProductPage">
            <div
              className="ProductListTitle"
              style={{ textAlign: "center", width: "100%" }}
            >
              <span
                style={{
                  ...webstyle.Heading,
                  textTransform: "capitalize",
                  fontWeight: 500,
                }}
              >
                {t("navbar.BioFungicides")}
              </span>
            </div>
            <ProductList products={products2} />
          </div>
          <div id="Bio-insecticides" className="backgroundImageOurProductPage">
            <div
              className="ProductListTitle"
              style={{ textAlign: "center", width: "100%" }}
            >
              <span
                style={{
                  ...webstyle.Heading,
                  textTransform: "capitalize",
                  fontWeight: 500,
                }}
              >
                {t("navbar.BioInsecticides")}
              </span>
            </div>
            <ProductList products={products3} />
          </div>
          <div id="Bio-kit" className="backgroundImageOurProductPage">
            <div
              className="ProductListTitle"
              style={{ textAlign: "center", width: "100%" }}
            >
              <span
                style={{
                  ...webstyle.Heading,
                  textTransform: "capitalize",
                  fontWeight: 500,
                }}
              >
                {t("navbar.BioKit")}
              </span>
            </div>
            <ProductList products={products4} />
          </div>
          <div id="Bio-Fertilizers" className="backgroundImageOurProductPage">
            <div
              className="ProductListTitle"
              style={{ textAlign: "center", width: "100%" }}
            >
              <span
                style={{
                  ...webstyle.Heading,
                  textTransform: "capitalize",
                  fontWeight: 500,
                }}
              >
                {t("navbar.BioFertilizers")}
              </span>
            </div>
            <ProductList products={products5} />
          </div>
          <div
            id="Botanic-Extract-Insecticides"
            className="backgroundImageOurProductPage"
          >
            <div
              className="ProductListTitle"
              style={{ textAlign: "center", width: "100%" }}
            >
              <span
                style={{
                  ...webstyle.Heading,
                  textTransform: "capitalize",
                  fontWeight: 500,
                }}
              >
                {t("navbar.BEI")}{" "}
              </span>
            </div>
            <ProductList products={products7} />
          </div>
          <div
            id="Chemical-Fungicides"
            className="backgroundImageOurProductPage"
          >
            <div
              className="ProductListTitle"
              style={{ textAlign: "center", width: "100%" }}
            >
              <span
                style={{
                  ...webstyle.Heading,
                  textTransform: "capitalize",
                  fontWeight: 500,
                }}
              >
                {t("navbar.ChemicalFungicides")}
              </span>
            </div>
            <ProductList products={products9} />
          </div>
          <div
            id="Micro-Nutrients"
            className="backgroundImageOurProductPage"
          >
            <div
              className="ProductListTitle"
              style={{ textAlign: "center", width: "100%" }}
            >
              <span
                style={{
                  ...webstyle.Heading,
                  textTransform: "capitalize",
                  fontWeight: 500,
                }}
              >
                {t("navbar.microNutrients")}
              </span>
            </div>
            <ProductList products={products12} />
          </div>
          <div id="algacide" className="backgroundImageOurProductPage">
            <div
              className="ProductListTitle"
              style={{ textAlign: "center", width: "100%" }}
            >
              <span
                style={{
                  ...webstyle.Heading,
                  textTransform: "capitalize",
                  fontWeight: 500,
                }}
              >
                {t("navbar.algacide")}
              </span>
            </div>
            <ProductList products={products13} />
          </div>
          <div
            id="Chemical-Insecticides"
            className="backgroundImageOurProductPage"
          >
            <div
              className="ProductListTitle"
              style={{ textAlign: "center", width: "100%" }}
            >
              <span
                style={{
                  ...webstyle.Heading,
                  textTransform: "capitalize",
                  fontWeight: 500,
                }}
              >
                {t("navbar.ChemicalInsecticides")}
              </span>
            </div>
            <ProductList products={products15} />
          </div>
          <div id="Sticker-Spreader" className="backgroundImageOurProductPage">
            <div
              className="ProductListTitle"
              style={{ textAlign: "center", width: "100%" }}
            >
              <span
                style={{
                  ...webstyle.Heading,
                  textTransform: "capitalize",
                  fontWeight: 500,
                }}
              >
                {t("navbar.Stsp")}
              </span>
            </div>
            <ProductList products={products16} />
          </div>
          <div id="PGR" className="backgroundImageOurProductPage">
            <div
              className="ProductListTitle"
              style={{ textAlign: "center", width: "100%" }}
            >
              <span
                style={{
                  ...webstyle.Heading,
                  textTransform: "capitalize",
                  fontWeight: 500,
                }}
              >
                {t("navbar.pgr")}
              </span>
            </div>
            <ProductList products={products17} />
          </div>
        </div>
      </div>
      <WhatsAppButton phoneNumber="+919823986199" />
      <Footer />
    </div>
  );
};

export default ProductPage;
